.accounts table {
  margin: auto;
}

.accounts table tbody tr:nth-child(odd) {
  background-color: #eaeaea;
}

.accounts table td,
th {
  padding: 1em;
}
