.header {
  display: flex;
  justify-content: space-between;
  background-color: #0e5298;
  color: white;
  margin-bottom: 0.5rem;
}

.header h2 {
  margin-left: 2rem;
}

.header-info {
  display: flex;
  align-items: center;
}

.header-info button {
  margin: 0rem 1rem;
  padding: 0.25rem;
}
